import { ChangeDetectionStrategy, Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FooterComponent implements OnInit {

  currentYear: number

  constructor(
    public app: AppService,
    private router: Router,
    private clipboard: Clipboard,
    private service: CommonService
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  routeToTechnzone() {
    window.open(`https://www.technzone.com/`, "_blank")
  }

  routeToTerms(section: any) {
    if (section == 'terms') {
      this.router.navigate(['/' + this?.app?.languageCode + '/terms'], {
        queryParams: {
          'section': 'terms',
        }
      });
    } else if (section == 'privacy') {
      this.router.navigate(['/' + this?.app?.languageCode + '/terms'], {
        queryParams: {
          'section': 'privacy',
        }
      });
    }
  }

  routeThenScroll(locationString, scrollId) {
    this.router.navigate(['/' + this?.app?.languageCode + locationString]).then(() => {
      setTimeout(() => {
        this.scrollToElement(scrollId);
      }, 1200);
    });
  }
  scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }


  copyPhoneNumber(number) {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
      this.clipboard.copy(number);
    } else {
      this.app.successMsg("تم النسخ!")
      this.clipboard.copy(number);
    }
  }

  copyEmail(e) {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
      this.clipboard.copy(e);
    } else {
      this.app.successMsg("تم النسخ!")
      this.clipboard.copy(e);
    }
  }

  copyLocation() {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
      this.clipboard.copy('Administrative Units No. B011-B012-B013 – Ground Floor – ARC Building Project – Plot No. B123-C11 – Smart Village – Cairo-Alexandria Desert Road, Kilometer 28');
    } else {
      this.app.successMsg("تم النسخ!")
      this.clipboard.copy('عنوان:  وحدات ادراية رقم  B011-B012-B013– الدور الارضى – مشروع مبنى ARC – ارض رقم B123-C11 – القرية الذكية – طريق القاهره الاسكندريه الصحراوى الكيلو 28');
    }
  }

  emailInput;
  subscriber() {
    if (this.emailInput.length == 0) { return }
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this?.emailInput?.match(regex)) {
      let request = new FormData();
      request.append("Email", this.emailInput);
      this.service.subscribeByEmail(request).subscribe(
        res => {
          if (res.errorCode == 0) {
            if (this?.app?.getUserLanguage() == 'en') {
              this.app.successMsg("Thank You")
            } else if (this?.app?.getUserLanguage() == 'ar') {
              this.app.successMsg("شكرًا لك")
            }
          } else {
            this.app.errorMsg(res.errorMessage)
          }
        }
      )
    } else {
      if (this?.app?.getUserLanguage() == 'en') {
        this.app.errorMsg("Please enter a valid email address.")
      } else if (this?.app?.getUserLanguage() == 'ar') {
        this.app.errorMsg("الرجاء إدخال عنوان بريد إلكتروني صحيح.")
      }
    }
  }

  routeToServiceWithFilter(value) {
    this.router.navigate(['/' + this?.app?.languageCode + '/services']);
    // this.router.navigate(['/' + this?.app?.languageCode + '/services'], {
    //   queryParams: {
    //     'filterMode': value,
    //   }
    // });
  }

  homeThenScrollToFAQs() {
    this.router.navigate(['/' + this?.app?.languageCode + '/home']).then(() => {
      setTimeout(() => {
        const element = document?.getElementById('FAQs');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 900);
    });
  }

  refreshIfInService(id) {
    console.warn(this.router.url)
    if (this?.router?.url?.includes('services-details')) {
      this.router.navigate(['/' + this?.app?.languageCode + '/services-details/' + id]).then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 200);
      });
    } else {
      this.router.navigate(['/' + this?.app?.languageCode + '/services-details/' + id])
    }
  }


}
