<div class="light fixed-top" [ngClass]="navbarType == 'navbarWithScroll' ? 'bgHalfTransparent' : ''">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light pt-0">
            <div class="spechContainer w-100">
                <!-- out -->

                <!-- <li class="nav-item my-2 pointer">
                    <img *ngIf="navMode == 'white'" data-aos="fade-in" data-aos-duration="800"
                        [routerLink]="'/' + app?.languageCode+'/home'" src="assets/img/navbar/logoH.png"
                        class="logoImg" alt="Logo" />
                    <img *ngIf="navMode == 'blue'" data-aos="fade-in" data-aos-duration="800"
                        [routerLink]="'/' + app?.languageCode+'/home'" src="assets/img/navbar/logoC.png"
                        class="logoImg" alt="Logo" />
                </li> -->

                <img data-aos="fade-down" data-aos-duration="800" [routerLink]="'/' + app?.languageCode+'/home'"
                    src="assets/img/navbar/sLogo.svg" class="logoImgMobile appearAt991Logo" alt="Logo" />


                <button #dropDownMobileNavbar class="navbar-toggler my-3 outBtn" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNav" data-aos="fade-down" data-aos-duration="800" aria-controls="navbarNav"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <!-- out -->
                <div class="d-flex justify-content-between navDisplayOut w-100 pt-2" data-aos="fade-in"
                    data-aos-duration="800">
                    <ul class="navbar-nav">
                        <li class="nav-item my-2 pointer">
                            <img *ngIf="navMode == 'white'" data-aos="fade-in" data-aos-duration="800"
                                [routerLink]="'/' + app?.languageCode+'/home'" src="assets/img/navbar/logoH.png"
                                class="logoImg" alt="Logo" />
                            <img *ngIf="navMode == 'blue'" data-aos="fade-in" data-aos-duration="800"
                                [routerLink]="'/' + app?.languageCode+'/home'" src="assets/img/navbar/logoC.png"
                                class="logoImg" alt="Logo" />
                        </li>
                    </ul>

                    <ul class="navbar-nav d-flex align-items-center pt-2" data-aos="fade-in" data-aos-duration="800">
                        <li class="nav-item hoverJ pointer mx-3">
                            <mat-icon (click)="headerMenu = true"
                                [ngClass]="navMode == 'blue' ? 'iconM' : 'iconL'">reorder</mat-icon>
                        </li>
                        <!-- <li class="nav-item hoverJ pointer mx-4">
                            <mat-icon [ngClass]="navMode == 'blue' ? 'iconM' : 'iconL'">search</mat-icon>
                        </li> -->
                        <li class="nav-item hoverJ pointer">
                            <a class="nav-link" [ngClass]="navMode == 'blue' ? 'nav-linkM' : 'nav-linkG'">
                                <p *ngIf="selectedLang == 'en'" (click)="setLanguage('ar')" class="m-0 pb-2"
                                    [ngClass]="{'font-GE-Dinar': this.app?.getUserLanguage() == 'en'}">عربي</p>
                                <p *ngIf="selectedLang == 'ar'" (click)="setLanguage('en')" class="m-0"
                                    [ngClass]="{'font-Kannada': this.app?.getUserLanguage() == 'ar'}">English</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- out -->
                <div class="collapse navbar-collapse navDisplay" id="navbarNav">

                    <ul class="navbar-nav">

                        <!-- <li class="nav-item my-2 mx-4">
                            <a class="nav-link" [routerLink]="'/' + app?.languageCode+'/home'"
                                routerLinkActive="active">{{"NavBar.HOME"|translate }}
                            </a>
                        </li> -->


                        <!-- (click)="secondBtn.open()" -->
                        <!-- <li class="my-2 mx-2 d-flex justify-content-between align-items-center pointer"> -->
                        <!-- <mat-select #secondBtn class="fa select-non" [value]="selectedLang"
                                (selectionChange)="setLanguage($event.value)">
                                <mat-option class="fa" value="en">EN</mat-option>
                                <mat-option class="fa" value="ar">AR</mat-option>
                            </mat-select>
                            <mat-icon class="mx-1">expand_more</mat-icon> -->
                        <!-- </li> -->

                        <li class="nav-item my-2 d-flex justify-content-between">
                            <div class="d-flex pt-1">
                                <!-- <div class="iconCircle" (click)="closeNavBarMobile()"><img class=""
                                        src="assets/img/icons/icon1.svg"></div>
                                <div class="mx-3 iconCircle" (click)="closeNavBarMobile()"><img class=""
                                        src="assets/img/icons/icon2.svg"></div>
                                <div class="iconCircle" (click)="closeNavBarMobile()"><img class=""
                                        src="assets/img/icons/ig.svg"></div> -->

                                <div (click)="app?.routeToX()" class="iconCircle mx-2"><img class=""
                                        src="assets/img/icons/x.svg"></div>
                                <!-- <div class="iconCircle mx-2"><img class="" src="assets/img/icons/icon2.svg"></div> -->
                                <div (click)="app?.routeToLin()" class="iconCircle mx-2"><img class=""
                                        src="assets/img/icons/ln.png"></div>


                            </div>
                            <div>
                                <button (click)="closeNavBarMobile()" (click)="routeAndCloseMenu('/contact-us')"
                                    class="btn btnSub w-100 fillEliteBtn">{{'Home.contactUs' | translate
                                    }}</button>
                            </div>
                        </li>

                        <hr class="hrMobile my-1">


                        <li class="nav-item my-2">
                            <div class="row">
                                <div class="col-4">
                                    <p class="linkMenu" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/home')">{{"NavBar.home"|translate
                                        }}</p>
                                    <!-- <p class="linkMenu" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/about-us')">
                                        {{"NavBar.aboutUs"|translate }}</p> -->
                                    <p class="linkMenu" (click)="closeNavBarMobile()"
                                        (click)="routeToComplainSectionInContactUs()">
                                        {{"NavBar.ComplaintHandling"|translate }}</p>
                                </div>
                                <div class="col-4">
                                    <p class="linkMenu" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/why-elite')">
                                        {{"NavBar.WhyElite"|translate }}</p>
                                    <p class="linkMenu" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/careers')">
                                        {{"NavBar.Careers"|translate }}</p>

                                </div>
                                <div class="col-4">
                                    <p class="linkMenu mb-0" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/services')">
                                        {{"NavBar.services"|translate }}</p>
                                </div>
                            </div>
                        </li>

                        <hr class="hrMobile my-1">

                        <li class="nav-item my-2 ">
                            <div class="row">


                                <div class="col-4">
                                    <p class="lastLineP pointer m-0" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/policies/privacy-policy')">
                                        {{'NavBar.PrivacyPolicy'
                                        | translate }}</p>

                                </div>

                                <div class="col-4">
                                    <!-- <p class="lastLineP pointer mx-4">{{'NavBar.faqs' | translate }}</p> -->
                                    <p class="lastLineP pointer m-0" (click)="closeNavBarMobile()"
                                        (click)="routeAndCloseMenu('/policies/terms-conditions')">
                                        {{'NavBar.TermsCondition' | translate }}</p>

                                </div>

                                <div class="col-4">
                                    <a class="nav-link" [ngClass]="navMode == 'blue' ? 'nav-linkM' : 'nav-linkG'">
                                        <p *ngIf="selectedLang == 'en'" (click)="setLanguage('ar')" class="m-0 pb-2"
                                            [ngClass]="{'font-GE-Dinar': this.app?.getUserLanguage() == 'en'}">عربي</p>
                                        <p *ngIf="selectedLang == 'ar'" (click)="setLanguage('en')" class="m-0"
                                            [ngClass]="{'font-Kannada': this.app?.getUserLanguage() == 'ar'}">English
                                        </p>
                                    </a>

                                </div>

                            </div>
                        </li>

                        <!-- <div>
                            <a class="nav-link" [ngClass]="navMode == 'blue' ? 'nav-linkM' : 'nav-linkG'">
                                <p *ngIf="selectedLang == 'en'" (click)="setLanguage('ar')" class="m-0 pb-2"
                                    [ngClass]="{'font-GE-Dinar': this.app?.getUserLanguage() == 'en'}">عربي</p>
                                <p *ngIf="selectedLang == 'ar'" (click)="setLanguage('en')" class="m-0"
                                    [ngClass]="{'font-Kannada': this.app?.getUserLanguage() == 'ar'}">English</p>
                            </a>
                        </div> -->



                    </ul>

                    <ul class="navbar-nav mx-3">
                        <!-- <img loading="lazy" src="assets/img/navbar/translate.svg" class="w-h-24 mx-1 pointer"
                            id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                        <div class="dropdown">
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item pointer" (click)="setLanguage('en')">English</a></li>
                                <li><a class="dropdown-item pointer" (click)="setLanguage('ar')">العربية</a></li>
                            </ul>
                        </div> -->
                    </ul>
                </div>
                <!-- out -->
            </div>
        </nav>
    </div>
</div>

<!-- Menu -->
<div (mouseleave)="headerMenu = false" class="headerMenu" *ngIf="headerMenu"
    [ngClass]="this.app?.getUserLanguage() == 'en' ? 'MenuBox' : 'MenuBoxAR'" [@slideFromUp]>
    <div class="container h-100 mt-5 mb-3">

        <div class="row mb-5">
            <div class="col-2">
                <img src="assets/img/icons/whiteLogo.png" class="logoImgWhite" />
            </div>
            <div class="col-9 d-flex align-items-end">

                <div class="w-100">
                    <div class="w-100 d-flex justify-content-between">

                        <p class="fontLk m-0">{{"NavBar.ourCred"|translate }}</p>
                        <!-- matTooltip="{{'NavBar.clickToCopy' | translate }}" matTooltipClass="tooltipCustom" -->
                        <p (click)="copyEmail(app?.configInfo?.phoneNumber)" class="fontLk m-0 font-Kannada pointer">
                            {{app?.configInfo?.phoneNumber}}</p>

                    </div>
                    <hr class="hrLine">
                </div>



            </div>
            <div class="col-1">
                <mat-icon class="xIconMenu pointer" (click)="headerMenu = false">close</mat-icon>
            </div>
        </div>

        <div class="row mx-3">
            <div class="col-3">
                <p class="linkMenu" (click)="routeAndCloseMenu('/home')">{{"NavBar.home"|translate }}</p>
                <p class="linkMenu" (click)="routeAndCloseMenu('/why-elite')">{{"NavBar.WhyElite"|translate }}</p>
                <p class="linkMenu" (click)="routeAndCloseMenu('/services')">{{"NavBar.services"|translate }}</p>
            </div>
            <div class="col-3">
                <p class="linkMenu" (click)="routeToComplainSectionInContactUs()">
                    {{"NavBar.ComplaintHandling"|translate }}</p>
                <p class="linkMenu" (click)="routeAndCloseMenu('/careers')">{{"NavBar.Careers"|translate }}</p>
            </div>
            <div class="col-3">
                <!-- <p class="linkMenu" (click)="routeAndCloseMenu('/about-us')">{{"NavBar.aboutUs"|translate }}</p> -->
            </div>
        </div>

        <div class="w-100 d-flex justify-content-end">
            <div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="mx-3"><button (click)="routeAndCloseMenu('/contact-us')"
                            class="btn btnSub w-100 fillEliteBtn">{{'Home.contactUs' | translate
                            }}</button></div>
                    <div (click)="app?.routeToX()" class="iconCircle mx-3"><img class="" src="assets/img/icons/x.svg">
                    </div>
                    <!-- <div class="iconCircle mx-3"><img class="" src="assets/img/icons/icon2.svg"></div> -->
                    <div (click)="app?.routeToLin()" class="iconCircle mx-3"><img class=""
                            src="assets/img/icons/ln.png"></div>
                </div>
            </div>
        </div>

        <hr class="hrLine mt-4 mb-3">

        <div class="w-100">
            <div class="mx-3 d-flex">
                <p class="lastLineP pointer mx-3" (click)="routeAndCloseMenu('/policies/privacy-policy')">
                    {{'NavBar.PrivacyPolicy' | translate }}</p>
                <!-- <p class="lastLineP pointer mx-4">{{'NavBar.faqs' | translate }}</p> -->
                <p class="lastLineP pointer mx-3" (click)="routeAndCloseMenu('/policies/terms-conditions')">
                    {{'NavBar.TermsCondition' | translate }}</p>
                <p class="lastLineP pointer mx-3" (click)="routeToBlank('https://rb.gy/5ihc1w')">
                    {{'NavBar.insCon' | translate }}</p>
            </div>
        </div>

    </div>
</div>
<!-- Menu -->