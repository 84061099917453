<footer class="footer pt-5 pb-1">
	<!-- Footer Top -->
	<div class="footer-top mb-Res mb-4">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
					<div class="mb-4" style="width: 193px;">
						<img [routerLink]="'/' + app?.languageCode+'/home'" src="assets/img/navbar/footerLogo.png"
							class="logoImgFooter mb-3 pointer" alt="Logo" />
						<div class="d-flex justify-content-around">
							<div (click)="app?.routeToX()" class="iconCircle"><img class=""
									src="assets/img/icons/x.svg"></div>
							<!-- <div class="iconCircle"><img class="" src="assets/img/icons/icon2.svg"></div> -->
							<div (click)="app?.routeToLin()" class="iconCircle"><img class=""
									src="assets/img/icons/ln.png"></div>
						</div>
					</div>
					<div class="whiteBoxSub p-4">
						<p class="m-0 subText mb-4">{{ 'Footer.subToElite' | translate }}</p>
						<div class="row">
							<div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
								<mat-form-field class="w-100 specificFooter" appearance="fill" fxFlex="auto">
									<mat-label class="text-white">{{'Footer.emailAddress' | translate }}</mat-label>
									<input [(ngModel)]="emailInput" autocomplete="off" type="text" matInput>
								</mat-form-field>
							</div>
							<div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex align-items-center mt-2">
								<button (click)="subscriber()"
									class="btn btnSub w-100 fillEliteBtn">{{'Footer.subscribe' | translate }}</button>
							</div>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 pt-5">
					<div class="row">
						<div class="col-6 mb-5">
							<p class="pTitleF pointer mb-4">{{ 'Footer.aboutUs' | translate }}</p>
							<div>

								<div [routerLink]="'/' + app?.languageCode+'/why-elite'"
									class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.whyChoose' | translate }}</p>
								</div>

								<div [routerLink]="'/' + app?.languageCode+'/careers'"
									class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.careers' | translate }}</p>
								</div>

								<!-- <div (click)="routeThenScroll('/home','vision')"
									class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.ourVision' | translate }}</p>
								</div> -->

							</div>
						</div>
						<div class="col-6 mb-5">
							<p class="pTitleF pointer mb-4">{{ 'Footer.services' | translate }}</p>
							<div>
								<div (click)="routeToServiceWithFilter('life')"
									class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'NavBar.allServices' | translate }}</p>
								</div>
								<!-- <div (click)="routeToServiceWithFilter('life')" class="d-flex align-items-center mb16 pos-rel" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.life' | translate }}</p>
								</div>
								<div (click)="routeToServiceWithFilter('noLife')" class="d-flex align-items-center mb16 pos-rel" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.noLife' | translate }}</p>
								</div>
								<div class="d-flex align-items-center mb16 pos-rel" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.Insurance' | translate }}</p>
								</div>
								<div class="d-flex align-items-center mb16 pos-rel" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.Customer' | translate }}</p>
								</div> -->
								<!-- <div (click)="refreshIfInService(4)" class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.medical' | translate }}</p>
								</div>
								<div (click)="refreshIfInService(5)" class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.motor' | translate }}</p>
								</div>
								<div (click)="refreshIfInService(14)" class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.general' | translate }}</p>
								</div>
								<div (click)="refreshIfInService(1)" class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.life' | translate }}</p>
								</div> -->
							</div>
						</div>
						<div class="col-6 mb-5">
							<p class="pTitleF pointer mb-4">{{ 'Footer.contact' | translate }}</p>
							<div>
								<div matTooltip="{{'NavBar.clickToCopy' | translate }}" matTooltipClass="tooltipCustom"
									(click)="copyEmail(app?.configInfo?.infoEmail)"
									class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{app?.configInfo?.infoEmail}}</p>
								</div>

								<div matTooltip="{{'NavBar.clickToCopy' | translate }}" matTooltipClass="tooltipCustom"
									(click)="copyEmail(app?.configInfo?.phoneNumber)"
									class="d-flex align-items-center mb16 pos-rel"
									[ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover"
										[ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0 font-Kannada">{{app?.configInfo?.phoneNumber}}</p>
								</div>

								<div matTooltip="{{'NavBar.clickToCopy' | translate }}" matTooltipClass="tooltipCustom"
									(click)="copyLocation()" class="d-flex align-items-center mb16 pos-rel">

									<p class="SubTitleF pointer m-0 font-Kannada">{{'Footer.location' | translate }}</p>
								</div>

								<!-- <div class="d-flex align-items-center mb16 pos-rel" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'hoverOnWrapper' : 'hoverOnWrapperAR'">
									<mat-icon class="d-flex align-items-center arrowIconHover" [ngClass]="this.app?.getUserLanguage() == 'en' ? 'arrowIconHover' : 'arrowIconHoverAR'">arrow_upward</mat-icon>
									<p class="SubTitleF pointer m-0">{{ 'Footer.num2' | translate }}</p>
								</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- /Footer Top -->

	<!-- Footer Bottom -->
	<div class="footer-bottom">
		<div class="container">

			<!-- Copyright -->
			<div class="copyright" style="direction: ltr !important;">
				<div class="row">
					<div class="col-md-3 col-lg-3 col-sm-12">
						<div class="copyright-text">
							<p class="mb-0 colorB pointer" (click)="routeToTechnzone()">Powered By Technzone |
								{{currentYear}}</p>
						</div>
					</div>
					<div class="col-md-2 col-lg-2 col-sm-12">
						<div class="copyright-text">
							<p class="mb-0 colorBW">{{ 'Footer.cr466' | translate }}</p>
						</div>
					</div>
					<div class="col-md-2 col-lg-2 col-sm-12">
						<div class="copyright-text">
							<p class="mb-0 colorBW">{{ 'Footer.regNo' | translate }}</p>
						</div>
					</div>
					<div class="col-md-2 col-lg-2 col-sm-12">
						<div class="copyright-text">
							<p class="mb-0 colorBW">{{ 'Footer.regData' | translate }}</p>
						</div>
					</div>
				</div>
			</div>
			<!-- /Copyright -->

		</div>
	</div>
	<!-- /Footer Bottom -->
</footer>